<template>
    <div v-if="errorMessage" class="alert alert-danger" role="alert">{{ errorMessage }}</div>
    <smart-input v-model="disputeReference" label="Dispute Reference" />
    <button v-if="isDisputesEditor && (mode === 'development' || mode === 'uat')" class="btn btn-primary" @click="onAssociateWithDispute">Associate with Dispute</button>
</template>

<script>
import { SmartInput } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
    name: 'SchemesAssociateWithDispute',
    components: {
        SmartInput,
    },
    data() {
        return {
            mode: import.meta.env.VITE_MODE,
            disputeReference: '',
            errorMessage: '',
        };
    },
    computed: {
      currentAccount() {
        return this.$store.state.auth.account;
      },
      isDisputesEditor() {
        return this.currentAccount && this.currentAccount.roles
          ? this.currentAccount.roles.includes('DisputesEditor')
          : false;
      },
    },
    methods: {
        async onAssociateWithDispute() {
            const results = await this.$store.dispatch('disputeSearch/searchDispute', {
                pageParameters: {
                    size: 10,
                    number: 0,
                },
                sort: {
                    item: 'updatedAt',
                    direction: 'descending',
                },
                fieldNames: [],
                searchTerm: this.disputeReference,
                operator: 1,
            });
            if (results.results?.length > 0) {
                this.$store
                .dispatch('disputeAssociation/newAssociation', { disputeId: results.results[0].disputeId, schemeId: this.$route.params.schemeId })
                .then(() => {
                    this.$router.push(`/disputes/${results.results[0].disputeId}/associations/create`);
                });
            } else {
                this.errorMessage = 'Dispute not found';
            }
        },
    },
};
</script>

