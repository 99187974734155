<template>
  <confirm-dialog ref="confirm"></confirm-dialog>
  <subtitle>Edit Contact</subtitle>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div v-if="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>

  <div v-if="model" class="h-100">
    <div class="pt-3">
      <div class="row">
        <div class="col-sm-12 col-md-10 col-lg-10">
          <card label="Contact Details">
            <edit-fields :v="v$" />

            <div v-if="adminLogin">
              <div class="form-group mb-3">
                <label class="form-label">Last Log In </label>
                <input
                  readonly
                  type="text"
                  :value="adminLogin.lastSignIn ? adminLogin.lastSignIn : 'Never'"
                  class="form-control"
                />
              </div>

              <div class="row">
                <div class="d-grid gap-2 d-md-flex">
                  <button
                    v-if="adminLogin.isDisabled && isSchemeUserAdmin"
                    type="button"
                    class="btn btn-secondary"
                    @click="enable"
                  >
                    Enable Login
                  </button>

                  <button
                    v-if="!adminLogin.isDisabled && isSchemeUserAdmin"
                    type="button"
                    class="btn btn-danger"
                    @click="disable"
                  >
                    Disable Login
                  </button>

                  <button
                    v-if="adminLogin && isSchemeUserAdmin"
                    type="button"
                    class="btn btn-secondary ms-2"
                    @click="resendInvite"
                  >
                    Resend Invite
                  </button>
                </div>
              </div>
            </div>

            <button
              v-if="!adminLogin && isSchemeUserAdmin"
              type="button"
              class="btn btn-secondary"
              @click="createAdminLogin"
            >
              Create Scheme Admin Login
            </button>
          </card>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
    <router-link class="btn btn-secondary float-right btn-lg" type="button" :to="parentRoute">
      <span>Cancel</span>
    </router-link>

    <smart-submit-large label="Save" :loading="loading" @click="save" />
  </div>
</template>

<script>
import {
  Card,
  confirmType,
  SmartSubmitLarge,
  Subtitle,
  ConfirmDialog,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import useValidate from '@vuelidate/core';
import EditFields from './SchemesEditContactsFields.vue';
import { validationModel } from './validation-model';

export default {
  name: 'SchemesEditContactsEdit',
  components: {
    ConfirmDialog,
    SmartSubmitLarge,
    EditFields,
    Card,
    Subtitle,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      successMessage: null,
      contactId: this.$route.params.contactId,
      v$: useValidate(),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isSchemeUserAdmin() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('SchemeUserAdmin')
        : false;
    },
    parentRoute() {
      return `/scheme/${this.$route.params.schemeId}/edit/contacts`;
    },
    model() {
      return this.$store.state.schemeContact.contact;
    },
    adminLogin() {
      return this.$store.state.schemeUser.user;
    },
  },
  mounted() {
    this.$store.dispatch('schemeUser/byEmail', {
      schemeId: this.$route.params.schemeId,
      accountId: this.model.email,
    });
  },
  methods: {
    resendInvite() {
      this.$store
        .dispatch('schemeUser/resendInvite', { accountId: this.model.email })
        .then(() => {
          this.$refs.confirm.show({
            title: 'Invite Resent',
            message: 'An invitation email has been resent as requested.',
            confirmType: confirmType.okOnly,
          });
        })
        .catch(() => {
          this.$refs.confirm.show({
            title: 'Error',
            message:
              'There was an issue resending the invitation email. The invitation email was NOT resent.',
            confirmType: confirmType.okOnly,
          });
        });
    },
    save() {
      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to save the contact. Please resolve any errors on this form and try again.';
        return;
      }

      const payload = this.model;
      this.$store
        .dispatch('schemeContact/updateContact', payload)
        .then((resp) => {
          this.$store
            .dispatch('schemeContact/listContacts', this.$route.params.schemeId)
            .then(() => {
              this.successMessage = resp;
              this.$router.push(this.parentRoute);
            });
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
    enable() {
      this.$store.dispatch('schemeUser/enable', { accountId: this.model.email });
    },
    disable() {
      this.$store.dispatch('schemeUser/disable', { accountId: this.model.email });
    },
    createAdminLogin() {
      const payload = {
        accountId: this.model.email,
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        schemeId: this.$route.params.schemeId,
      };

      this.$store
        .dispatch('schemeUser/invite', payload)
        .then(() => {
          this.successMessage = 'Invite sent';
          this.$store.dispatch('schemeUser/get', this.$route.params.schemeId);
          this.showAddControls = false;
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations: {
    model: validationModel,
  },
};
</script>
