<template>
  <form ref="formContact" class="has-validation">
    <smart-input
      id="firstName"
      v-model="model.firstName"
      label="First Name"
      :is-required="!!v$.model.firstName.isRequired"
      :validator="v$.model.firstName"
    />

    <smart-input
      id="lastName"
      v-model="model.lastName"
      label="Last Name"
      :is-required="!!v$.model.lastName.isRequired"
      :validator="v$.model.lastName"
    />

    <smart-input
      id="jobTitle"
      v-model="model.jobTitle"
      label="Job Title"
      :is-required="!!v$.model.jobTitle.isRequired"
      :validator="v$.model.jobTitle"
    />

    <smart-input
      id="primaryContactNumber"
      v-model="model.primaryContactNumber"
      label="Primary Contact Number"
      :is-required="!!v$.model.primaryContactNumber.isRequired"
      :validator="v$.model.primaryContactNumber"
    />

    <smart-input
      id="email"
      v-model="model.email"
      label="Email"
      :is-required="!!v$.model.email.isRequired"
      :validator="v$.model.email"
    />

    <smart-select-binary
      id="isComplianceContact"
      v-model="model.isComplianceContact"
      label="Is Compliance Contact"
      :is-required="!!v$.model.isComplianceContact.isRequired"
      :validator="v$.model.isComplianceContact"
    />

    <smart-select-binary
      id="isFinanceContact"
      v-model="model.isFinanceContact"
      label="Is Finance Contact"
      :is-required="!!v$.model.isFinanceContact.isRequired"
      :validator="v$.model.isFinanceContact"
    />

    <smart-select-binary
      id="Is Operation Contact"
      v-model="model.isOperationContact"
      label="Is Operation Contact"
      :is-required="!!v$.model.isOperationContact.isRequired"
      :validator="v$.model.isOperationContact"
    />

    <smart-select-binary
      id="Is Website Contact"
      v-model="model.isWebsiteContact"
      label="Is Website Contact"
      :is-required="!!v$.model.isWebsiteContact.isRequired"
      :validator="v$.model.isWebsiteContact"
    />

    <smart-select-binary
      id="ReceivesCat1Notifications"
      v-model="model.receivesCat1Notifications"
      label="Receives CAT1 Notifications"
      :is-required="!!v$.model.receivesCat1Notifications.isRequired"
      :validator="v$.model.receivesCat1Notifications"
    />

    <smart-select-binary
      id="receivesNonCat1Notifications"
      v-model="model.receivesNonCat1Notifications"
      label="Receives Non-CAT1 Notifications"
      :is-required="!!v$.model.receivesNonCat1Notifications.isRequired"
      :validator="v$.model.receivesNonCat1Notifications"
    />

  </form>
</template>

<script>
import { SmartInput, SmartSelectBinary } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemesEditContactsFields',
  components: {
    SmartInput,
    SmartSelectBinary,
  },
  props: {
    v: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {
    v$() {
      return this.v;
    },
    model() {
      return this.$store.state.schemeContact.contact;
    },
  },
};
</script>
