<template>
  <confirm-dialog ref="confirmDeleteContact"></confirm-dialog>
  <div>
    <subtitle>Contacts</subtitle>

    <div class="h-100 pt-3">
      <div class="row">
        <div class="col">
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>

          <div class="mb-4">
            <div class="btn-toolbar mb-2 mb-md-0">
              <div class="btn-group me-2">
                <button
                  v-if="isSchemeContactEditor"
                  class="btn btn-secondary"
                  @click="createContact"
                >
                  Create Contact
                </button>
              </div>
            </div>
          </div>

          <table v-if="contacts" class="table table-hover">
            <thead>
              <tr>
                <th scope="col">First name</th>
                <th scope="col">Last name</th>
                <th scope="col">Job Title</th>
                <th scope="col">Email</th>
                <th scope="col">Number</th>
                <th scope="col">Modified</th>
                <th scope="col" style="width: 1px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="contact in contacts" :key="contact.contactId">
                <td>
                  {{ contact.firstName }}
                </td>
                <td>
                  {{ contact.lastName }}
                </td>
                <td>
                  {{ contact.jobTitle }}
                </td>
                <td>
                  <span v-if="contact.email && contact.email.length > 0">
                    <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
                  </span>
                  <span v-if="contact.receivesCat1Notifications" class="ms-1 badge bg-primary">CAT 1</span>
                  <span v-if="contact.receivesNonCat1Notifications" class="ms-1 badge bg-secondary">Non-CAT 1</span>
                </td>
                <td>
                  <span
                    v-if="contact.primaryContactNumber && contact.primaryContactNumber.length > 0"
                  >
                    <a :href="`tel:${contact.primaryContactNumber}`">{{
                      contact.primaryContactNumber
                    }}</a>
                  </span>
                </td>
                <td>
                  {{ $helpers.getLastModifiedString(contact) }}
                </td>
                <td>
                  <div class="btn-group btn-group">
                    <button
                      v-if="isSchemeContactEditor"
                      class="btn btn-outline-secondary"
                      @click="editContact(contact.contactId)"
                    >
                      Edit
                    </button>
                    <button
                      v-if="isSchemeContactEditor"
                      type="button"
                      class="btn btn-outline-danger"
                      @click="confirmDelete(contact.contactId)"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-if="contacts && contacts.length === 0">
            No contacts have been added for this scheme.
          </p>

          <div v-if="loading && contacts.length === 0" class="ghost">Loading contacts...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  confirmType,
  Subtitle,
  ConfirmDialog,
} from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'SchemesEditContactsList',
  components: {
    Subtitle,
    ConfirmDialog,
  },
  data() {
    return {
      errorMessage: null,
      loading: true,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isSchemeContactEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('SchemeContactEditor')
        : false;
    },
    parentRoute() {
      return `/scheme/${this.$route.params.schemeId}/edit/contacts`;
    },
    contacts() {
      return this.$store.state.schemeContact.contacts;
    },
  },
  mounted() {
    if (!this.contacts || this.contacts.length === 0) {
      this.listContacts();
    }
  },
  methods: {
    listContacts() {
      this.$store
        .dispatch('schemeContact/listContacts', this.$route.params.schemeId)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err;
        });
    },
    createContact() {
      this.$store.dispatch('schemeContact/newContact', this.$route.params.schemeId).then(() => {
        this.$router.push(`${this.parentRoute}/create`);
      });
    },
    editContact(contactId) {
      const payload = {
        contactId,
        schemeId: this.$route.params.schemeId,
      };
      this.$store.dispatch('schemeContact/getContact', payload).then(() => {
        this.$router.push(`${this.parentRoute}/edit/${contactId}`);
      });
    },
    deleteContact(contactId) {
      const payload = {
        contactId,
        schemeId: this.$route.params.schemeId,
      };
      this.$store.dispatch('schemeContact/deleteContact', payload).then(() => {
        this.listContacts();
      });
    },
    confirmDelete(contactId) {
      this.$refs.confirmDeleteContact
        .show({
          title: 'Delete Contact',
          message: 'Are you sure you want to delete this contact?',
          confirmType: confirmType.destructive,
        })
        .then((result) => {
          if (result === true) {
            this.deleteContact(contactId);
          }
        });
    },
  },
};
</script>
