import DisputeAssociationService from '../services/dispute-association-service';
  
export const disputeAssociation = {
  namespaced: true,
  state: {
    associations: null,
    association: null,
  },
  actions: {
    newAssociation({ commit }, disputeId) {
      const template = {
      };
      const empty = JSON.parse(JSON.stringify(template));
      if (typeof disputeId === 'object') {
        empty.disputeId = disputeId.disputeId;
        if (disputeId.schemeId) {
          empty.schemeId = disputeId.schemeId;
          empty.disputeAssociationType = 'Scheme';
        }
      } else {
        empty.disputeId = disputeId;
      }
      empty.unknownBusiness = {};
      commit('setAssociation', empty);
    },
    listAssociations({ commit }, disputeId) {
      commit('setIsBusy', true, { root: true });
      return DisputeAssociationService.list(disputeId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setAssociations', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    createAssociation({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return DisputeAssociationService.create(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    getAssociation({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      commit('setAssociation', null);
      return DisputeAssociationService.get(payload.disputeId, payload.disputeAssociationId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setAssociation', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateAssociation({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return DisputeAssociationService.update(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    deleteAssociation({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return DisputeAssociationService.delete(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setAssociations(state, newAssociations) {
      state.associations = newAssociations;
    },
    setAssociation(state, newAssociation) {
      state.association = newAssociation;
      if (state.association && !state.association.unknownBusiness) {
        state.association.unknownBusiness = {};
      }
    },
  },
};
