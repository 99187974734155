import DisputesService from '../services/disputes-service';
import DisputeService from '../services/dispute-service';

export const disputes = {
  namespaced: true,
  state: {
    project: null,
    dispute: null,
  },
  actions: {
    getByProjectReference({ commit }, projectReference) {
      return DisputeService.getByProjectReference(projectReference);
    },
    getByLodgementCertificateNumber({ commit }, lodgementCertificateNumber) {
      return DisputeService.getByLodgementCertificateNumber(lodgementCertificateNumber);
    },
    getByUMR({ commit }, umr) {
      return DisputeService.getByUMR(umr);
    },
    getProject({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      commit('setProject', []);
      return DisputesService.getProject(id)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setProject', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    authorise({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      // commit('setBlockedEmails', []);
      return DisputesService.authorise(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    create({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return DisputeService.create(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setDispute', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    update({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return DisputeService.update(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setDispute', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    delete({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return DisputeService.delete(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    get({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      return DisputeService.get(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setDispute', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setProject(state, newState) {
      state.project = newState;
    },
    setDispute(state, newState) {
      state.dispute = newState;
    },
  },
};
